<template>
  <div class="row">
    <div class="col-md-12">
      <template>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12" lg="11">
                <v-row>
                  <v-col cols="12" lg="2">
                    <v-text-field
                        v-model="tableFilters.code"
                        append-icon="filter_alt"
                        :label="$t('PAGES.RESERVATIONS.LIST.FILTERS.CODE')"
                        single-line
                        hide-details
                        clearable
                        @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                        v-model="tableFilters.name"
                        append-icon="filter_alt"
                        :label="$t('PAGES.RESERVATIONS.LIST.FILTERS.NAME')"
                        single-line
                        hide-details
                        clearable
                        @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                        v-model="tableFilters.surname"
                        append-icon="filter_alt"
                        :label="$t('PAGES.RESERVATIONS.LIST.FILTERS.SURNAME')"
                        single-line
                        hide-details
                        clearable
                        @keyup.enter.native="searchWithFilter()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-text-field
                        v-model="tableFilters.fiscalCode"
                        append-icon="search"
                        :label="$t('PAGES.RESERVATIONS.LIST.FILTERS.FISCAL_CODE')"
                        single-line
                        hide-details
                        clearable
                        @keyup.enter.native="searchWithFilter()"
                        g
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-menu
                        ref="dateReservationDateFrom"
                        v-model="dateReservationDateFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="reservation_date_time_from_formatted"
                            clearable
                            :label="
                            $t(
                              'PAGES.RESERVATIONS.LIST.FILTERS.RESERVATIONS_FROM'
                            )
                          "
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            append-icon="calendar_today"
                            @click:clear="onClearDateClicked('from')"
                        ></v-text-field>
                        {{ attr }}
                      </template>
                      <v-date-picker
                          v-model="tableFilters.reservation_date_time_from"
                          :first-day-of-week="1"
                          locale="it-it"
                          actions
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="
                            $refs.dateReservationDateFrom.save(
                              tableFilters.reservation_date_time_from
                            )
                          "
                        >
                          {{ $t("COMMON.DIALOG.OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="2">
                    <v-menu
                        ref="dateSubscriptionDateTo"
                        v-model="dateSubscriptionDateTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        max-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="reservation_date_time_to_formatted"
                            clearable
                            :label="
                            $t(
                              'PAGES.RESERVATIONS.LIST.FILTERS.RESERVATIONS_TO'
                            )
                          "
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                            append-icon="calendar_today"
                            @click:clear="onClearDateClicked('to')"
                        ></v-text-field>
                        {{ attr }}
                      </template>
                      <v-date-picker
                          v-model="tableFilters.reservation_date_time_to"
                          :first-day-of-week="1"
                          locale="it-it"
                          actions
                          :min="
                          tableFilters.reservation_date_time_from
                            ? tableFilters.reservation_date_time_from
                            : ''
                        "
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="
                            $refs.dateSubscriptionDateTo.save(
                              tableFilters.reservation_date_time_to
                            )
                          "
                        >
                          {{ $t("COMMON.DIALOG.OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" lg="1">
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    class="ma-q white--text mt-3"
                    @click="searchWithFilter()"
                >
                  {{ $t("PAGES.RESERVATIONS.LIST.FILTERS.FILTER") }}
                  <v-icon right dark>mdi-filter</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              fixed-header
              class="elevation-1"
              :headers="tableData.headers"
              :pageCount="totalPages"
              :page="1"
              :server-items-length="totalResults"
              :items="dataTableList"
              :loading="reservationLoading"
              :options.sync="options"
              item-key="ID"
              :items-per-page="itemsPerPage"
              :footer-props="footerProps"
          >
            <template v-slot:item.status="{ item }">
                <span>
                  <v-icon color="green" v-if="item.status == true"
                  >mdi-check-circle</v-icon
                  >
                  <v-icon color="red" v-else>mdi-minus-circle</v-icon>
                </span>
            </template>
            <template v-slot:item.code="{ item }">
              {{ item.code }}
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <span v-if="item.date_of_birth != null">{{
                  new Date(item.date_of_birth).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric"
                  })
                }}</span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt != null"
              >{{
                  new Date(item.createdAt).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.reservation_date_time="{ item }">
              <span v-if="item.reservation_date_time != null"
              >{{
                  new Date(item.reservation_date_time).toLocaleString([], {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric"
                  })
                }}
              </span>
              <span v-else>---</span>
            </template>
            <template v-slot:item.subscription_date="{ item }">
              <span v-if="item.subscription_date != null">{{
                  new Date(item.subscription_date).toLocaleDateString()
                }}</span>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn text icon color="green">
                <v-icon
                    @click="$router.push('/reservations/overview/' + item.id)"
                >mdi-folder-account
                </v-icon
                >
              </v-btn>
              <v-btn text icon color="blue" v-show="false">
                <v-icon @click="manageItem(item)">mdi-pencil</v-icon>
              </v-btn>
              <v-btn text icon color="red">
                <v-icon @click="deleteItem(item)">mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline"
                    >{{ $t("COMMON.DIALOG.DELETE_CONFIRM") }}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                      >{{ $t("COMMON.DIALOG.ABORT") }}
                      </v-btn>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm"
                      >{{ $t("COMMON.DIALOG.OK") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialog" max-width="500px" :persistent="true">
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import moment from "moment";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DELETE_RESERVATION, FETCH_RESERVATIONS_LIST} from "@/core/services/store/reservations.module";
import {mapGetters} from "vuex";
import {FETCH_FULL_STATES_LIST} from "@/core/services/store/states.module";

export default {
  data() {
    return {
      totalPage: Number,
      totalResults: Number,
      filter_list: {
        code: {q: "code", t: "_like"},
        name: {q: "citizen.name", t: "_like"},
        surname: {q: "citizen.surname", t: "_like"},
        status: {q: "status", t: "_eq"},
        fiscalCode: {q: "citizen.fiscalCode", t: "_like"},
        reservation_date_time_from: {q: "reservation_date_time", t: "_gt"},
        reservation_date_time_to: {q: "reservation_date_time", t: "_lt"}
      },
      dialog_edit: false,
      filters: {
        query: [],
        filters: {},
        sort: [
          {
            direction: "DESC",
            field: "id"
          }
        ],
        page: 1,
        limit: 10
      },
      single_item_loading: false,
      dialog: false,
      dialogDelete: false,
      page: 0,
      totalItems: 0,
      numberOfPages: 1,
      options: {},
      itemsPerPage: 10,
      optionsLength: 6,
      pagination: {
        page: 1,
        rowsPerPage: 20,
        itemsPerPage: 20
      },
      footerProps: {
        showFirstLastPage: true,
        "items-per-page-options": [10, 20, 50, 100],
        "items-per-page-text": this.$t("COMMON.TABLES.PAGINATION")
      },

      // users_list_data: [],
      tableFilters: {},
      tableData: {
        headers: [
          {
            text: this.$t("COMMON.TABLES.COLUMN.CODE"),
            align: "left",
            sortable: true,
            value: "code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.NAME"),
            value: "citizen.name"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.SURNAME"),
            value: "citizen.surname"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.FISCAL_CODE"),
            value: "citizen.fiscal_code"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.EVENT_TITLE"),
            value: "event.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.DOSE"),
            value: "dose.title"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.RESERVATION_DATE"),
            value: "reservation_date_time"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.REGISTRATION_DATE"),
            value: "createdAt"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.STATUS"),
            value: "status"
          },
          {
            text: this.$t("COMMON.TABLES.COLUMN.OPERATION"),
            value: "action",
            sortable: false
          }
        ]
      },
      editedItem: {},
      defaultItem: {},
      item_to_edit: {},
      empty_filters: {
        query: [],
        filters: {},
        sort: {},
        page: 1
      },
      status: [
        {
          value: 1,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_1")
        },
        {
          value: 2,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_2")
        },
        {
          value: 3,
          name: this.$t("PAGES.CITIZENS.LIST.FILTERS.STATUS_3")
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["reservationsList", "fullStateList", "reservationLoading"]),
    dataTableList() {
      return this.reservationsList.data;
    },
    reservation_date_time_from_formatted() {
      return this.tableFilters.reservation_date_time_from
          ? moment(String(this.tableFilters.reservation_date_time_from)).format(
              "DD/MM/YYYY"
          )
          : "";
    },
    reservation_date_time_to_formatted() {
      return this.tableFilters.reservation_date_time_to
          ? moment(String(this.tableFilters.reservation_date_time_to)).format(
              "DD/MM/YYYY"
          )
          : "";
    }
  },
  components: {},
  methods: {
    convertDataToPicker(date) {
      if (date != undefined) {
        return new Date(date).getDate();
      }
    },
    onClearDateClicked(type) {
      type == "from"
          ? (this.tableFilters.reservation_date_time_from = "")
          : (this.tableFilters.reservation_date_time_to = "");
    },
    readDataFromAPI(reset) {
      const {page, itemsPerPage, sortBy, sortDesc} = this.options;
      let pageNumber;
      if (reset == true) {
        pageNumber = 1;
        this.filters.limit = itemsPerPage;
      } else {
        pageNumber = page == 0 ? 1 : page;
        this.filters.limit = itemsPerPage;
      }
      this.filters.page = pageNumber;
      if (sortBy != undefined && sortBy.length > 0) {
        this.filters.sort = [
          {
            direction: sortDesc != undefined && sortDesc[0] ? "DESC" : "ASC",
            field: sortBy[0]
          }
        ];
      }
      this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
    },
    refreshFilters() {
    },
    searchWithFilter() {
      let self = this;
      var tmp;
      Object.keys(this.filter_list).forEach(function (value) {
        if (
            self.tableFilters[value] == undefined ||
            self.tableFilters[value] == null ||
            self.tableFilters[value] === ""
            // self.tableFilters[value]
        ) {
          self.$delete(self.filters.filters, self.filter_list[value].q);
        } else {
          if (
              value == "reservation_date_time_from" ||
              value == "reservation_date_time_to"
          ) {
            switch (value) {
              case "reservation_date_time_from":
                tmp = {};
                tmp.reservationDateTime = {
                  _gt: self.tableFilters["reservation_date_time_from"]
                };
                self.filters.filters["_from"] = tmp;
                if (
                    self.tableFilters["reservation_date_time_to"] == undefined ||
                    self.tableFilters["reservation_date_time_to"] == null ||
                    self.tableFilters["reservation_date_time_to"] == ""
                ) {
                  tmp = {};
                  tmp.reservationDateTime = {
                    _lt: new Date().toISOString().slice(0, 10)
                  };
                  self.filters.filters["_to"] = tmp;
                }
                break;
              case "reservation_date_time_to":
                tmp = {};
                tmp.reservationDateTime = {
                  _lt: self.tableFilters["reservation_date_time_to"]
                };
                self.filters.filters["_to"] = tmp;
                if (
                    self.tableFilters["reservation_date_time_from"] ==
                    undefined ||
                    self.tableFilters["reservation_date_time_from"] == null ||
                    self.tableFilters["reservation_date_time_from"] == ""
                ) {
                  tmp = {};
                  tmp.reservationDateTime = {
                    _gt: "1900-01-01"
                  };
                  self.filters.filters["_from"] = tmp;
                }
                break;
            }
            self.filters.filters["_cmb_and"] = "_from,_to";
          } else {
            switch (self.filter_list[value].t) {
              case "_like":
                self.filters.filters[self.filter_list[value].q] = {
                  _like: "%" + self.tableFilters[value] + "%"
                };
                break;
              case "_eq":
                self.filters.filters[self.filter_list[value].q] = {
                  _eq: self.tableFilters[value]
                };
                break;
              case "_gt":
                self.filters.filters[self.filter_list[value].q] = {
                  _gt: self.tableFilters[value]
                };
                break;
              case "_lt":
                self.filters.filters[self.filter_list[value].q] = {
                  _lt: self.tableFilters[value]
                };
                break;
            }
          }
        }
      });

      this.readDataFromAPI(true);
    },
    zeroFill(number, width = 8) {
      width -= number.toString().length;
      if (width > 0) {
        return (
            new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number
        );
      }
      return number + ""; // always return a string
    },
    checkTextFiltert(text) {
      if (text != undefined) {
        return "%" + text + "%";
      }
      return "%";
    },
    updateDialogLoading(status) {
      this.single_item_loading = status;
    },
    manageItem(item) {
      this.$router.push("/reservations/edit/" + item.id);
    },
    deleteItem(item) {
      this.editedIndex = this.dataTableList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dataTableList.splice(this.editedIndex, 1);
      this.$store.dispatch(DELETE_RESERVATION, this.editedItem);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("PAGES.RESERVATIONS.AREA")},
      {title: this.$t("PAGES.RESERVATIONS.LIST.TITLE")}
    ]);
    this.$store.dispatch(FETCH_RESERVATIONS_LIST, this.filters);
    this.$store.dispatch(FETCH_FULL_STATES_LIST, this.empty_filters);
  },
  watch: {
    reservationsList() {
      this.totalPage = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
      this.totalResults = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total
          : 0;
      this.page = this.reservationsList.meta != undefined
          ? this.reservationsList.meta.pagination.total_pages
          : 0;
    },
    dialog(val) {
      val || this.close();
    },
    dialog_edit(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.readDataFromAPI();
      }
    }
  }
};
</script>
